/* 招聘前台 */
<template>
  <div id="list" style="width: 1200px; margin: 0 auto" class="hireHall_list">
    <iframe
      v-if="showIf"
      id="ifraHome"
      width="100%"
      :height="heigths + 'px'"
      :src="url"
      frameborder="0"
    ></iframe>
    <searchHeaders style="margin-top: 10px" @searchMsg="searchMsg" />
    <el-row :gutter="15" style="padding: 5px 0px">
      <el-col
        :span="8"
        v-for="(item, index) in tableList"
        :key="index"
        @click.native="gotoDetail(item)"
      >
        <div class="box">
          <div class="df line1">
            <h3>{{ (item.position && item.position.name) || "--" }}</h3>
            <div style="color: #f74848; fontsize: 16px; fontweight: 800">
              {{ item.position && item.position.min_salary / 1000 }}-{{
                item.position && item.position.max_salary / 1000
              }}K
            </div>
          </div>
          <div class="line2">
            {{ (item.company && item.company.name) || "--" }}
          </div>
          <div class="line3">
            <el-tag style="margin-right: 10px">
              {{ (item.post_type && item.post_type.name) || "--" }}
            </el-tag>
            <el-tag style="margin-right: 10px">
              {{ getDegree(item.position && item.position.degree) }}
            </el-tag>
            <el-tag>
              {{
                (item.position &&
                  item.position.work_duration.substring(
                    0,
                    item.position.work_duration.indexOf(".")
                  )) ||
                "--"
              }}年
            </el-tag>
          </div>
          <div class="line4 df">
            <!-- <p>
              {{ item.company && item.company.industry.replace(/>/g, " | ") }}
            </p> -->
            <p
              class="ellipsisText"
              style="width: 208px; color: #666"
              :title="showTalent(item)"
            >
              {{ showTalent(item) }}
            </p>
            <p>{{ item.work_place_city || "--" }}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" align="right">
        <div>
          <to-top domName=".hireHall_list" />
        </div>
      </el-col>
      <el-col :span="24" align="center" v-if="showLoad">
        <!-- <fw-pageNumber align="right" :pages="pages" @changePage="changePage" /> -->
        <button class="button-57" role="button" @click="changePage">
          <span class="text">查看更多</span>
          <span>
            <i class="el-icon-bottom"></i>
            <i class="el-icon-bottom"></i>
            <i class="el-icon-bottom"></i>
          </span>
        </button>
      </el-col>
    </el-row>
    <!-- 组件 -->
    <fw-imports titleShow="选择人才分类" ref="fwImports" />
  </div>
</template>
<script>
import searchHeaders from "../components/searchHeader.vue";
import ToTop from "../components/toTop.vue";
export default {
  name: "",
  components: { ToTop, searchHeaders },
  data() {
    return {
      platInfo: {
        logo_url: "",
      },
      seach: "",
      _code: [], //工作地点
      tabpane: [
        {
          name: "省",
          id: "0",
          value: 10000,
          is_show: false,
        },
        {
          name: "市",
          id: "1",
          value: null,
          is_show: false,
        },
        {
          name: "区",
          id: "2",
          value: null,
          is_show: false,
        },
      ],
      activeName: "0",
      showList: [], //地区类别列表
      tableList: [], //表格数据
      getData: {},
      otherSearArr: [
        {
          name: "学历要求",
          type: 1,
          is_open: false,
        },
        {
          name: "薪资范围",
          type: 2,
          is_open: false,
        },
        {
          name: "发布时间",
          type: 3,
          is_open: false,
        },
        {
          name: "工作年限",
          type: 4,
          is_open: false,
        },
      ],
      showSearchNum: 0, //1.学历要求2.薪资范围3.发布时间4.工作年限5.职位类型

      /* 学历 相关*/
      degreeOptions: [
        {
          name: "学历不限",
          id: 0,
        },
        {
          name: "高中",
          id: 1,
        },
        {
          name: "专科",
          id: 2,
        },
        {
          name: "本科",
          id: 3,
        },
        {
          name: "硕士",
          id: 4,
        },
        {
          name: "博士",
          id: 5,
        },
      ],
      degreeArr: {
        name: null,
        id: null,
      }, //学历搜索回显
      degree: [], //学历搜索
      /* 薪资 相关*/
      salariesOptions: [
        {
          name: "不限",
          id: 0,
        },
        {
          name: "4K以下",
          id: 1,
        },
        {
          name: "4K-6K",
          id: 2,
        },
        {
          name: "6K-8K",
          id: 3,
        },
        {
          name: "8K-10k",
          id: 4,
        },
        {
          name: "10K-15K",
          id: 5,
        },
        {
          name: "15K-25K",
          id: 6,
        },
        {
          name: "25K-35K",
          id: 7,
        },
        {
          name: "35K-50K",
          id: 8,
        },
        {
          name: "50K以上",
          id: 9,
        },
      ],
      salariesArr: {
        name: null,
        id: null,
      }, //薪资范围
      salaries: [], //薪资范围搜索用
      // //已选数组,
      // type:1工作地址2人才类别3.学历4.薪资
      chosedArr: [], //已选的数据显示用
      talent: [], //人才类别搜索用
      pages: {
        //页码
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      showDegIcon: null,
      listNum: 9, //数据个数
      showLoad: true, //显示或隐藏加载更多
      defaultSearch: {},
      appJudge: null,
      url: null,
      showIf: false,
      heigths: 300,
      company_id: 0,
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        console.log(val);
        let judge = val.fullPath.includes("company_id");
        if (judge) {
          this.appJudge = true;
          this.company_id = val.query.company_id;
        } else {
          this.appJudge = false;
        }
      },
      deep: true,
      immediate: true,
      page_id: null,
    },
  },
  created() {
    this.getAppass();
    // this.url = `https://aa.testfw.cn/_apass/lookcustomV3?id=${this.page_id}`;

    this.$utils
      .getPlatformInfo()
      .then((res) => {
        this.platInfo = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.$route.query.company_id) {
      this.defaultSearch.company_id = this.$route.query.company_id;
    }
    this.query();
  },
  mounted() {
    window.addEventListener("message", this.setHeight);
    let data = JSON.parse(sessionStorage.getItem("platform_name_logo") || "");
    console.log(data);
    document.title = data.value.platform.name + "-" + "招聘大厅";
  },
  methods: {
    showTalent(item) {
      let msg = "";
      item.talent.forEach((item) => {
        msg += item.name + ";";
      });
      return msg;
    },
    setHeight(event) {
      console.log(event);
      if (event?.data.height) {
        this.heigths = event.data.height || 300;
      }
      if (event?.data.url) {
        // routerToother(event.data.url);
      }
    },
    getAppass() {
      let api = "";
      if (this.appJudge) {
        //企业
        api = `/zhaopin/public/company/page/get_enable`;

        this.$axios.get(api,{company_id:this.company_id}).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.page_id = res.data.page_json.page_id;
              let domin_url = location.origin;
              this.url = `${domin_url}/_apass/lookcustomV3?id=${this.page_id}`;
              this.showIf = true;
            }
          }
        });
      } else {
        //平台
        api = "/zhaopin/public/platform/page/get_enable";
        this.$axios.get(api).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.page_id = res.data.page_json.page_id;
              let domin_url = location.origin;
              this.url = `${domin_url}/_apass/lookcustomV3?id=${this.page_id}`;
              this.showIf = true;
            }
          }
        });
      }
    },
    searchMsg(val) {
      this.defaultSearch = val;
      this.query();
    },
    gotoDetail(item) {
      console.log(111);
      this.$router.push({
        path: "/hireHall/list/detail",
        query: {
          id: item.id,
        },
      });
    },
    query() {
      let params = {
        ...this.defaultSearch,
        page: 1,
        page_size: this.listNum,
      };
      this.$axios
        .postJSON("/zhaopin/public/notoken/recruit/list", params)
        .then((res) => {
          // console.log('res dataf',res.data);
          if (res.code == 200) {
            this.tableList = res.data.data;
            this.pages.total = res.data.total;
            console.log(this.tableList);
            if (this.listNum > res.data.total) {
              this.showLoad = false;
            } else {
              this.showLoad = true;
            }
          }
        });
    },
    changePage(val) {
      this.listNum = this.listNum + 9;
      setTimeout(() => {
        this.query();
      }, 300);
    },
    searchBtn() {
      this.defaultSearch = {};
      this.query();
    },
    uniqueFunc(arr, uniId) {
      const res = new Map();
      return arr.filter(
        (item) => !res.has(item[uniId]) && res.set(item[uniId], 1)
      );
    },
    getDegree(num) {
      let msg = "--";
      if (num == 0) {
        msg = "学历不限";
      } else if (num == 1) {
        msg = "高中";
      } else if (num == 2) {
        msg = "专科";
      } else if (num == 3) {
        msg = "本科";
      } else if (num == 4) {
        msg = "硕士";
      } else if (num == 5) {
        msg = "博士";
      }
      return msg;
    },
  },
};
</script>
<style lang="scss" scoped>
#list {
  min-height: calc(100vh - 100px);
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.df {
  display: flex;
  align-items: center;
}
.bar {
  background-color: #fff;
  margin: 10px 0;
}
.bar1 {
  height: 120px;
  .plat-logo {
    height: 32px;
    width: auto;
    cursor: pointer;
    margin-right: 15px;
  }
}
.bar2 {
  padding: 20px 60px;
}
.bar3 {
  padding: 20px 60px;
}
.citys {
  cursor: pointer;
  margin-right: 50px;
  &:hover {
    color: $themeColor;
  }
}

.box {
  min-height: 175px;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px 0;
  .line1 {
    padding: 0 15px;
    line-height: 50px;
    justify-content: space-between;
    border-bottom: 1px dashed #e7eaef;
  }
  .line2 {
    padding: 0 15px;
    line-height: 40px;
  }
  .line3 {
    padding: 0 15px;
    line-height: 40px;
  }
  .line4 {
    padding: 0 15px;
    line-height: 40px;
    color: #cccccc;
    justify-content: space-between;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    color: $themeColor;
  }
}
::v-deep .serBar {
  .el-input__inner {
    border: 3px solid $themeColor;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    border: 3px solid $themeColor;
    background-color: $themeColor;
    color: #fff;
  }
}
</style>
<style lang="scss">
/* CSS */
.button-57 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $themeColor;
  color: $themeColor;
  display: inline-block;
  font-size: 14px;
  line-height: 3px;
  padding: 14px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 75%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -35%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $themeColor;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}
.el-dropdown-link {
  &:hover {
    cursor: pointer;
    color: #409eff;
  }
}
.el-icon-arrow-down {
  font-size: 12px;
}
.leibies {
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 10px;
  }
}
</style>
