<template>
  <div id="searchHeaders">
    <div class="inputs">
      <div :class="isFocus ? 'haseFocus' : ''">
        <input
          placeholder="输入职位、公司等搜索"
          class="inputShu"
          :value="inputValue"
          type="text"
          @input="inputChange"
          @focus="isFocus = true"
          @blur="isFocus = false"
        />
      </div>
      <div @click="searchData">
        <i class="el-icon-search"></i>
      </div>
    </div>
    <div class="diqun">
      <div>工作地址：</div>
      <div :class="formaDate.shen.name ? 'isChose' : ''">
        <i
          @click="delet(1)"
          v-show="formaDate.shen.name"
          style="margin-right:5px;cursor: pointer;"
          class="el-icon-close"
        ></i>
        <span style="cursor: pointer;" @click="choseAddess(1)">{{
          formaDate.shen.name || "省"
        }}</span
        ><i
          @click="choseAddess(1)"
          style="margin-left:10px;color:#333;cursor: pointer;"
          :class="
            formaDate.shen.open ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
          "
        ></i>
      </div>

      <div :class="formaDate.si.name ? 'isChose' : ''">
        <i
          @click="delet(2)"
          v-show="formaDate.si.name"
          style="margin-right:5px;cursor: pointer;"
          class="el-icon-close"
        ></i>
        <span style="cursor: pointer;" @click="choseAddess(2)">{{
          formaDate.si.name || "市"
        }}</span
        ><i
          @click="choseAddess(2)"
          style="margin-left:10px;color:#333;cursor: pointer;"
          :class="
            formaDate.si.open ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
          "
        ></i>
      </div>

      <!-- <div :class="formaDate.qu.name ? 'isChose' : ''">
        <i
          @click="delet(3)"
          v-show="formaDate.qu.name"
          style="margin-right:5px;cursor: pointer;"
          class="el-icon-close"
        ></i>
        <span style="cursor: pointer;" @click="choseAddess(3)">{{
          formaDate.qu.name || "区"
        }}</span
        ><i
          @click="choseAddess(3)"
          style="margin-left:10px;color:#333;cursor: pointer;"
          :class="
            formaDate.qu.open ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
          "
        ></i>
      </div> -->
    </div>

    <div
      v-show="formaDate.shen.open || formaDate.si.open || formaDate.qu.open"
      class="showCity"
    >
      <div
        :class="
          formaDate.shen.name == item ||
          formaDate.si.name == item ||
          formaDate.qu.name == item
            ? 'isChose'
            : ''
        "
        v-for="(item, key) in array"
        :key="key"
        @click="choseThis(item, key)"
      >
        {{ item }}
      </div>
    </div>
    <div class="rencai">
      <div>人才类别：</div>
      <div>
        <span v-if="chosedArr.length == 0" @click="jobClass">请选择</span>
        <span
          v-for="(item, index) in chosedArr"
          :key="item.id"
          style="margin-right:15px"
        >
          <i @click="delets(index)" class="el-icon-close"></i>
          <span @click="jobClass" style="margin-left:5px">{{ item.name }}</span>
        </span>
      </div>
    </div>

    <div class="otherSearch">
      <el-dropdown
        v-for="(item, index) in otherSearch"
        :key="index"
        placement="bottom-start"
        @command="
          (val) => {
            command(val, item);
          }
        "
      >
        <span class="el-dropdown-link">
          <template v-if="item.value">
            <i
              @click="delt(item)"
              style="margin-right:5px;cursor: pointer;color:#0088fe;"
              class="el-icon-close"
            ></i>
            <span style="color:#0088fe;">{{ item.choseName }}</span>
            <i class="el-icon-caret-bottom" style="color:#0088fe;"></i>
          </template>
          <template v-else>
            <span>{{ item.name }}</span>
            <i class="el-icon-caret-bottom"></i>
          </template>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="its"
            v-for="its in item.array"
            :key="its.id"
          >
            <div
              :style="
                `width:120px;color:${item.value == its.id ? '#0088fe' : ''}`
              "
            >
              {{ its.name }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 组件 -->
    <fw-imports titleShow="选择人才分类" ref="fwImports" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      cityList: {},
      array: {},
      isFocus: false,
      inputValue: "",
      chosedArr: [], //已选的数据显示用
      otherSearch: [
        {
          name: "学历要求",
          open: false,
          value: "",
          choseName: "",
          array: [
            {
              name: "学历不限",
              id: 0,
            },
            {
              name: "高中",
              id: 1,
            },
            {
              name: "专科",
              id: 2,
            },
            {
              name: "本科",
              id: 3,
            },
            {
              name: "硕士",
              id: 4,
            },
            {
              name: "博士",
              id: 5,
            },
          ],
        },
        {
          name: "薪资要求",
          open: false,
          value: 0,
          choseName: "",
          array: [
            {
              name: "不限",
              id: 0,
              min_salary: 0, //薪资最低
              max_salary: 1000000000, //薪资最高
            },
            {
              name: "4K以下",
              id: 1,
              min_salary: 0, //薪资最低
              max_salary: 4000, //薪资最高
            },
            {
              name: "4K-6K",
              id: 2,
              min_salary: 4000, //薪资最低
              max_salary: 6000, //薪资最高
            },
            {
              name: "6K-8K",
              id: 3,
              min_salary: 6000, //薪资最低
              max_salary: 8000, //薪资最高
            },
            {
              name: "8K-10k",
              id: 4,
              min_salary: 8000, //薪资最低
              max_salary: 10000, //薪资最高
            },
            {
              name: "10K-15K",
              id: 5,
              min_salary: 10000, //薪资最低
              max_salary: 15000, //薪资最高
            },
            {
              name: "15K-25K",
              id: 6,
              min_salary: 15000, //薪资最低
              max_salary: 25000, //薪资最高
            },
            {
              name: "25K-35K",
              id: 7,
              min_salary: 25000, //薪资最低
              max_salary: 35000, //薪资最高
            },
            {
              name: "35K-50K",
              id: 8,
              min_salary: 35000, //薪资最低
              max_salary: 50000, //薪资最高
            },
            {
              name: "50K以上",
              id: 9,
              min_salary: 50000, //薪资最低
              max_salary: 1000000000000, //薪资最高
            },
          ],
        },
        {
          name: "工作经验",
          open: false,
          value: 0,
          choseName: "",
          array: [
            {
              name: "不限",
              id: 0,
              min: 0,
              max: 9999,
            },
            {
              name: "一年以下",
              id: 1,
              min: 0,
              max: 1,
            },
            {
              name: "1-3年",
              id: 2,
              min: 1,
              max: 3,
            },
            {
              name: "3-5年",
              id: 3,
              min: 3,
              max: 5,
            },
            {
              name: "5-10年",
              id: 4,
              min: 5,
              max: 10,
            },
            {
              name: "10年以上",
              id: 5,
              min: 10,
              max: 9999,
            },
          ],
        },
        {
          name: "岗位类型",
          open: false,
          value: 0,
          choseName: "",
          array: [
            {
              name: "不限",
              id: 0,
            },
            {
              name: "全职",
              id: 1,
            },
            {
              name: "兼职",
              id: 2,
            },
            {
              name: "实习",
              id: 3,
            },
            {
              name: "其他",
              id: 4,
            },
          ],
        },
      ],
      formaDate: {
        shen: {
          name: "",
          code: "",
          open: false,
        },
        si: {
          name: "",
          code: "",
          open: false,
        },
        qu: {
          name: "",
          code: "",
          open: false,
        },
      },
    };
  },
  created() {
    this.$axios.get("/city/list/all").then((res) => {
      console.log(res);
      this.cityList = res.data;
    });
  },
  methods: {
    delets(index) {
      this.chosedArr.splice(index, 1);
      this.searchData();
    },
    jobClass() {
      this.$axios
        .postJSON("/zhaopin/public/platform/talent_classify/popup", {
          is_all: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            let array = [];
            res.data.map((it) => {
              let obj = {};
              obj.name = it.name;
              obj.value = it.id;
              obj.array = [];
              if (it.chird && it.chird.length) {
                let subArray = [];
                it.chird.map((its) => {
                  subArray.push({
                    name: its.name,
                    value: its.id,
                  });
                });
                obj.array = subArray;
              }
              array.push(obj);
            });

            let disable = this.chosedArr.map((itt) => {
              if (itt.type == 2) {
                return itt.id;
              }
            });
            this.$refs.fwImports.open(array, disable, (val) => {
              let chooseVal = [];
              val.map((it) => {
                if (it.array && it.array.length > 0) {
                  it.array.map((its) => {
                    chooseVal.push({ id: its.value, name: its.name });
                  });
                }
              });
              if (!chooseVal.length) {
                return this.$message.error("请选择人才类别");
              }
              if (chooseVal.length > 5) {
                return this.$message.error("人才类别最多选择5个");
              }
              console.log(chooseVal);
              let choseArr = chooseVal.map((itt) => {
                let obj = {
                  type: 2,
                  name: itt.name,
                  id: itt.id,
                };
                return obj;
              });

              this.chosedArr = [...choseArr];
              this.searchData();
            });
          }
        });
    },
    delt(item) {
      item.choseName = "";
      item.value = 0;
      this.searchData();
    },
    command(val, item) {
      item.choseName = val.name;
      item.value = val.id;
      this.searchData();
    },
    init() {
      // 初始化
      this.formaDate.shen.open = false;
      this.formaDate.si.open = false;
      this.formaDate.qu.open = false;
      this.array = [];
    },
    choseThis(item, key) {
      if (this.formaDate.shen.open) {
        this.formaDate.shen.name = item;
        this.formaDate.shen.code = key;

        this.formaDate.si.name = "";
        this.formaDate.si.code = "";

        this.formaDate.qu.name = "";
        this.formaDate.qu.code = "";
      }

      if (this.formaDate.si.open) {
        this.formaDate.si.name = item;
        this.formaDate.si.code = key;

        this.formaDate.qu.name = "";
        this.formaDate.qu.code = "";
      }

      if (this.formaDate.qu.open) {
        this.formaDate.qu.name = item;
        this.formaDate.qu.code = key;
      }
      this.searchData();
    },
    delet(val) {
      this.init();
      if (val == 1) {
        this.formaDate.shen.name = "";
        this.formaDate.shen.code = "";
        this.formaDate.si.name = "";
        this.formaDate.si.code = "";
        this.formaDate.qu.name = "";
        this.formaDate.qu.code = "";
      } else if (val == 2) {
        this.formaDate.si.name = "";
        this.formaDate.si.code = "";

        this.formaDate.qu.name = "";
        this.formaDate.qu.code = "";
      } else {
        this.formaDate.qu.name = "";
        this.formaDate.qu.code = "";
      }
      this.choseAddess(val);
      this.searchData();
    },
    choseAddess(val) {
      if (val == 1) {
        if (this.formaDate.shen.open) {
          this.formaDate.shen.open = false;
        } else {
          this.init();
          this.$nextTick(() => {
            this.array = this.cityList[100000];
            this.$nextTick(() => {
              this.formaDate.shen.open = true;
            });
          });
        }
      } else if (val == 2) {
        if (this.formaDate.shen.code) {
          if (this.formaDate.si.open) {
            this.formaDate.si.open = false;
          } else {
            this.init();
            this.$nextTick(() => {
              this.array = this.cityList[this.formaDate.shen.code];
              this.$nextTick(() => {
                this.formaDate.si.open = true;
              });
            });
          }
        } else {
          this.$message.error("请先选择省份");
        }
      } else if (val == 3) {
        if (this.formaDate.si.code) {
          if (this.formaDate.qu.open) {
            this.formaDate.qu.open = false;
          } else {
            this.init();
            this.$nextTick(() => {
              this.array = this.cityList[this.formaDate.si.code];
              this.$nextTick(() => {
                this.formaDate.qu.open = true;
              });
            });
          }
        } else {
          this.$message.error("请先选择省份");
        }
      }
    },
    inputChange(e) {
      this.inputValue = e.target.value;
    },
    searchData() {
      let talent = [];
      let degree = [];
      let min_salary = "";
      let max_salary = "";
      let work_place_province = null;
      let work_place_city = null;
      let work_place_area = null;

      if (this.formaDate.shen.code) {
        work_place_province = this.formaDate.shen.name;
      }
      if (this.formaDate.si.code) {
        work_place_city = this.formaDate.si.name;
      }
      if (this.formaDate.qu.code) {
        work_place_area = this.formaDate.qu.name;
      }

      this.chosedArr.forEach((item) => {
        talent.push(item.id);
      });
      const id = this.otherSearch[1].value;
      let msg = this.otherSearch[1].array.find((item) => {
        return item.id == id;
      });
      min_salary = msg.min_salary;
      max_salary = msg.max_salary;

      if (this.otherSearch[0].value) {
        degree.push(this.otherSearch[0].value);
      }

      let work_duration = {};
      const ids = this.otherSearch[2].value;
      let msgs = this.otherSearch[2].array.find((item) => {
        return item.id == ids;
      });
      work_duration = {
        min: msgs.min,
        max: msgs.max,
      };

      let data = {
        work_place_province,
        work_place_city,
        work_place_area,
        position_name: "", //职位名称
        classify_id: [], //职位分类
        min_salary, //薪资最低
        max_salary, //薪资最高
        work_duration,
        degree, //学历要求1=高中及以下,2=专科,3=本科,4=硕士,5=博士
        post_type: this.otherSearch[3].value, //1=全职，2=兼职，3=实习，4=其他
        title: this.inputValue, //搜索框
        talent, //人才类别
      };
      console.log(data);
      this.$emit("searchMsg", data);
    },
  },
};
</script>

<style lang="scss" scoped>
#searchHeaders {
  background-color: #fff;
  padding: 20px 32px 10px 32px;
  .inputs {
    margin: 0 auto;
    width: 836px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    & > div:nth-child(1) {
      border: 2px solid #eee;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: none;
      flex: 1;
      overflow: hidden;
      .inputShu {
        width: 90%;
        height: 36px;
        border: none;
        padding-left: 10px;
      }
    }
    & > div:nth-child(2) {
      width: 100px;
      background-color: #096efd;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
      i {
        font-size: 20px;
        color: #fff;
      }
    }
    .haseFocus {
      border-color: #096efd !important;
    }
  }
  .diqun {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 10px;
    & > div {
      margin-right: 20px;
    }
  }
  .isChose {
    color: #096efd;
  }
  .showCity {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 4px 12px !important;
    margin: 8px 0;
    & > div {
      padding: 9px 12px;
      color: #333;
      border-radius: 4px;
      box-sizing: border-box;
      &:hover {
        background: #f7f7f7;
        cursor: pointer;
      }
    }
    .isChose {
      background: #096efd !important;
      color: #fff !important;
    }
  }
  .rencai {
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    & > div:nth-child(1) {
      width: 80px;
    }
    & > div:nth-child(2) {
      flex: 1;
      overflow: hidden;
      span {
        color: #0088fe;
        cursor: pointer;
      }
    }
  }
  .otherSearch {
    padding: 10px 0 5px 10px;
    & > div {
      width: 15%;
    }
  }
}
</style>
