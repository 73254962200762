<template>
  <div class="back-to-top empha" ref="btn" @click="totop" title="回到顶部">
    <!-- <i class="el-icon-caret-top"></i> -->
    TOP
  </div>
</template>
<script>
export default {
  name: "name",
  props: ["domName"],
  mounted() {
    this.scroll();
  },
  methods: {
    totop() {
      let body = window.document.querySelector(this.domName);
      let stepNum = 10;
      let scrollTop = body
        ? body.scrollTop
        : document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      let timeId = setInterval(() => {
        stepNum += stepNum;
        if (scrollTop <= 0) clearInterval(timeId);
        let sum = (scrollTop -= stepNum);
        if (body) {
          body.scrollTop = sum;
        } else {
          document.documentElement.scrollTop = sum;
          window.pageYOffset = sum;
          document.body.scrollTop = sum;
        }
      }, 80);
    },
    scroll() {
      let body = window.document.querySelector(this.domName || "body");
      body.onscroll = () => {
        let scrollTop =
          body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        let innerHeight = window.innerHeight - 500;
        let opacityNum = 1 - (innerHeight - scrollTop) / innerHeight;
        let resNum = opacityNum > 1 ? 1 : opacityNum;
        this.$refs["btn"].style.opacity = scrollTop <= 0 ? 0 : resNum;
        this.$refs["btn"].style.display = scrollTop > innerHeight ? "block" : "none";
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  right: 8%;
  bottom: 4%;
  opacity: 0;
  display: none;
  background-size: cover;
  z-index: 9;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #409eff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-weight: bolder;
  &:hover {
    background-color: rgb(210, 236, 254);
    color: rgb(35, 35, 35);
  }
}
</style>
